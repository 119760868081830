// Dependencies
import React from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'

// Components
import Layout from '../components/layout'
import Hero from '../components/Hero'
import About from '../components/About'
import Helmet from '../components/Helmet'
import Social from '../components/Social'

// Helpers
import { getNavigationProps, getExtraSections } from '../utils'

const AboutPage = (props) => {
  const langLinks = [
    {
      url: props.path.replace(`/${props.pageContext.lang}`, ''),
      lang: props.pageContext.lang,
      active: true,
    },
    ...get(props, 'data.prismicAboutContent.alternate_languages'),
  ]

  const sections = [
    get(props, 'data.allPrismicGalleriesContent'),
    get(props, 'data.allPrismicTestimonialsContent'),
    get(props, 'data.allPrismicServicesContent'),
  ]

  const navigationProps = getNavigationProps(props)

  const heroCopy = get(props, 'data.prismicAboutContent.data.hero_copy', '')
  const heroImage = get(
    props,
    'data.prismicAboutContent.data.hero_image.document.data',
    {}
  )
  const heroProps = {
    title: heroCopy,
    image: heroImage,
  }

  const introTitle = get(props, 'data.prismicAboutContent.data.intro_title', '')
  const introCopy = get(
    props,
    'data.prismicAboutContent.data.intro_copy.html',
    ''
  ).replace(/(https?:\/\/.\/)/gim, '/')

  const followTitle = get(
    props,
    'data.prismicAboutContent.data.follow_title',
    ''
  )
  const followCopy = get(
    props,
    'data.prismicAboutContent.data.follow_copy.html',
    ''
  )
  const profilePicture = get(
    props,
    'data.prismicAboutContent.data.profile_image.document.data',
    {}
  )
  const aboutProps = {
    aboutTitle: introTitle,
    aboutCopy: introCopy,
    profilePicture,
    followTitle,
    followCopy,
    instagram: navigationProps.instagram,
    facebook: navigationProps.facebook,
  }

  const generalContent = get(
    props,
    'data.allPrismicGeneralContent.nodes[0].data',
    {}
  )

  const socialProps = {
    instagram: navigationProps.instagram,
    facebook: navigationProps.facebook,
    followCopy,
    latestPostsLabel: generalContent.latest_posts_label,
    loadMorePosts: generalContent.load_more_posts,
  }

  return (
    <Layout {...{ ...navigationProps, langLinks }}>
      <Helmet {...generalContent} />
      {heroProps.image && <Hero {...heroProps} compact />}
      <About {...aboutProps} />
      {getExtraSections(sections, generalContent, props.pageContext.lang)}
      <Social {...socialProps} />
    </Layout>
  )
}

export const aboutPageQuery = graphql`
  query($id: String, $lang: String) {
    allPrismicNavigation(filter: { lang: { eq: $lang } }) {
      nodes {
        lang
        data {
          links {
            page {
              uid
              lang
            }
            label
          }
        }
      }
    }
    allPrismicGeneralContent(filter: { lang: { eq: $lang } }) {
      nodes {
        data {
          ...generalContent
        }
      }
    }
    prismicAboutContent(id: { eq: $id }, lang: { eq: $lang }) {
      alternate_languages {
        url
        lang
      }
      data {
        hero_copy
        hero_image {
          document {
            ... on PrismicHeroImage {
              data {
                portrait {
                  fluid(maxWidth: 1650, imgixParams: { q: 100 }) {
                    ...fluidImage
                  }
                }
                landscape {
                  fluid(maxWidth: 2800, imgixParams: { q: 100 }) {
                    ...fluidImage
                  }
                }
              }
            }
          }
        }
        intro_title
        intro_copy {
          html
        }
        follow_title
        follow_copy {
          html
        }
        profile_image {
          document {
            ... on PrismicProfileImage {
              data {
                squared {
                  fluid(maxWidth: 800, imgixParams: { q: 100 }) {
                    ...fluidImage
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicGalleriesContent(filter: { lang: { eq: $lang } }) {
      __typename
      nodes {
        url
        data {
          ...galleriesContent
        }
      }
    }
    allPrismicServicesContent(filter: { lang: { eq: $lang } }) {
      __typename
      nodes {
        url
        data {
          ...servicesContent
        }
      }
    }
    allPrismicTestimonialsContent(filter: { lang: { eq: $lang } }) {
      __typename
      nodes {
        url
        data {
          ...testimonialsContent
        }
      }
    }
  }
`

export default AboutPage
