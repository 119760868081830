// Dependencies
import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

// Components
import { instagramSVG, facebookSVG } from '../../assets/svgs'

// Styles
import './about.scss'

const About = ({
  aboutTitle,
  aboutCopy,
  profilePicture,
  followTitle,
  followCopy,
  instagram,
  facebook,
}) => {
  return (
    <>
      <section>
        <div className="section-content padded">
          <div className="about">
            <div className="about__profile">
              <div className="about-profile__image">
                {profilePicture &&
                  profilePicture.squared &&
                  profilePicture.squared.fluid && (
                    <Img fluid={profilePicture.squared.fluid} />
                  )}
              </div>
            </div>
            <div className="about__content">
              <h2>{aboutTitle}</h2>
              <div dangerouslySetInnerHTML={{ __html: aboutCopy }} />

              {(instagram || facebook) && (
                <>
                  <h3>{followTitle}</h3>
                  <div dangerouslySetInnerHTML={{ __html: followCopy }} />

                  <div className="navigation__social">
                    {facebook && (
                      <a
                        aria-label="Facebook Profile Link"
                        href={facebook}
                        target="_blank"
                        rel="noreferrer noopener"
                        onClick={(e) => {
                          trackCustomEvent({
                            category: 'Social Link About',
                            action: 'Click',
                            label: 'Facebook',
                          })
                        }}
                        dangerouslySetInnerHTML={{ __html: facebookSVG }}
                      />
                    )}
                    {instagram && (
                      <a
                        aria-label="Instagram Profile Link"
                        href={instagram}
                        target="_blank"
                        rel="noreferrer noopener"
                        onClick={(e) => {
                          trackCustomEvent({
                            category: 'Social Link About',
                            action: 'Click',
                            label: 'Instagram',
                          })
                        }}
                        dangerouslySetInnerHTML={{ __html: instagramSVG }}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

// Components PropTypes
About.propTypes = {
  aboutTitle: PropTypes.string.isRequired,
  aboutCopy: PropTypes.string.isRequired,
  profilePicture: PropTypes.shape({
    fluid: PropTypes.shape({
      src: PropTypes.string,
      srcSet: PropTypes.string,
      srcWebp: PropTypes.string,
      srcSetWebp: PropTypes.string,
      sizes: PropTypes.string,
      aspectRatio: PropTypes.number,
      base64: PropTypes.string,
    }),
  }).isRequired,
  followTitle: PropTypes.string.isRequired,
  followCopy: PropTypes.string.isRequired,
  instagram: PropTypes.string.isRequired,
  facebook: PropTypes.string.isRequired,
}

export default About
